/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Navigate } from "react-router-dom";
// ---------------------------------------------------------
import {
  userApplicant,
  userCompany,
  userInfluencer,
  userSuperAdmin,
} from "./Helpers";
// ---------------------------------------------------------

export const ProtectedRouteCompany = ({ children }) => {
  const { token } = userCompany.get();

  if (token === "") return <Navigate to="/login-company" />;

  return <>{children}</>;
};

export const ProtectedRouteApplicant = ({ children }) => {
  const { token } = userApplicant.get();

  if (token === "") return <Navigate to="/login-applicant" />;

  return <>{children}</>;
};

export const ProtectedRouteInfluencer = ({ children }) => {
  const { token } = userInfluencer.get();

  if (token === "") return <Navigate to="/login-associated" />;

  return <>{children}</>;
};

export const ProtectedRouteSuperAdmin = ({ children }) => {
  const { token } = userSuperAdmin.get();

  if (token === "") return <Navigate to="/login-superadmin" />;

  return <>{children}</>;
};
