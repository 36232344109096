/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./FormForApplicant.css";
import { t } from "../../languages/settingsLanguages";
import TabBarSteps, { Step } from "../../components/tabBarSteps/TabBarSteps";
import Input, {
  ChildrenLabelDefault,
  Options,
  Selector,
  TextArea,
} from "../../components/input/Input";
import {
  option_choose,
  experienceOptions,
  lngPrefOptions,
  okOptions,
  travelOptions,
  adjetivesOptions,
  additionalServices,
  licenseOptions,
  genderOptions,
  availableOptions,
} from "../../utils/options";
import servicesFormApplicant, {
  servicesUpdateImageProfile,
} from "./servicesFormApplicant";
import Button from "../../components/button/Button";
import { thousandsSeparator, userApplicant } from "../../utils/Helpers";
import HeaderInAdmin from "../../components/header/HeaderInAdmin";
import assets from "../../assets/assets";
// ---------------------------------------------------------

const FormForApplicant = ({ docTitle }) => {
  const [currentFormStep, setCurrentFormStep] = useState(1);
  const [arrCompleted, setArrCompleted] = useState([
    { completed: true },
    { completed: true },
    { completed: true },
    { completed: true },
    { completed: true },
  ]);
  const {
    perfil,
    name,
    lastname,
    city,
    zip,
    tel,
    birthdate,
    gender,
    job,
    apply,
    timeexp,
    travel,
    selfdescription,
    availability,
    lngpref,
    license,
    salary,
    teamwork,
    pressure,
    working,
    skills,
    adaptability,
    objectively,
    learning,
    responsibility,
    extrahours,
    satisfied,
    comment,
    id,
    token,
  } = userApplicant.get();

  let parse_adjetives = {
    one: "",
    two: "",
    three: "",
  };
  let parse_additional_services = [];

  const [form, setForm] = useState({
    // ------------- Step 1 -------------
    applicant_profileFile: perfil,
    applicant_name: name,
    applicant_lastname: lastname,
    applicant_residence_city: city,
    applicant_postal_code: zip,
    applicant_phone_number: tel,
    applicant_birthdate: birthdate,
    applicant_gender: gender,
    // applicant_mail: "",
    // applicant_password: "",
    // applicant_repeat_password: "",
    // applicant_terms: false,
    // ------------- Step 2 -------------
    applicant_what_job: job,
    applicant_apply: apply,
    applicant_adjetives: {
      one: parse_adjetives.one,
      two: parse_adjetives.two,
      three: parse_adjetives.three,
    },
    // ------------- Step 3 -------------
    applicant_time_exp: timeexp,
    applicant_travel: travel,
    applicant_selfdescription: selfdescription,
    applicant_availability: availability,
    applicant_lng_pref: lngpref,
    applicant_driving_license: license,
    applicant_salary: salary,
    applicant_additional_services: [...parse_additional_services],
    // ------------- Step 4 -------------
    applicant_teamwork: teamwork,
    applicant_stress_and_pressure: pressure,
    applicant_I_enjoy_working: working,
    applicant_I_improve_my_skills: skills,
    applicant_adaptability: adaptability,
    // ------------- Step 5 -------------
    applicant_conflicts_objectively: objectively,
    applicant_I_enjoy_learning: learning,
    applicant_responsibility: responsibility,
    applicant_extra_hours: extrahours,
    applicant_satisfied_in_job: satisfied,
    applicant_short_comment: comment,
    token: token,
  });
  const [errorFree, setErrorFree] = useState({
    ...form,
    applicant_adjetives: true,
    applicant_short_comment: true,
  });
  const [validAge, setValidAge] = useState("");
  const [isLoadingTabVisible, setIsLoadingTabVisible] = useState(false);
  const [isResultTabVisible, setIsResultTabVisible] = useState(false);
  const [responseChangeProfile, setResponseChangeProfile] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isResultTabVisible !== "successful") {
        // The standard message that will be displayed may be different depending on the browser.
        let mensaje = "Are you sure you want to go out?";

        // Set the return message for the event.
        e.returnValue = mensaje; // Standard for some browsers.

        return mensaje; // Some browsers may require the message to be returned.
      }
    };

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isResultTabVisible]);

  // ---------------- Regular expresions ----------------
  let regIsMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let regIsPhone = /^\+?\d{10,13}$/;
  // ---------------- Regular expresions ----------------

  const handleChangeStep = (step) => {
    setCurrentFormStep(step);
  };

  const handleChangeNextStep = (step) => {
    let next = step + 1;

    handleChangeStep(next);
  };

  const handleChangeBackStep = (step) => {
    let back = step - 1;

    handleChangeStep(back);
  };

  const handleChangeInput = (target, isOK) => {
    setForm({
      ...form,
      [target.name]: target.value,
    });

    setErrorFree({
      ...errorFree,
      [target.name]: isOK,
    });
  };

  // const handleChangeCheckbox = (target) => {
  //   setForm({
  //     ...form,
  //     applicant_terms: target.checked,
  //   });

  //   setErrorFree({
  //     ...errorFree,
  //     applicant_terms: target.checked,
  //   });
  // };

  const handleChangeAdjetives = (value) => {
    const setFormAdjectives = (name, value) => {
      setForm({
        ...form,
        applicant_adjetives: {
          ...form.applicant_adjetives,
          [name]: value,
        },
      });
    };

    if (form.applicant_adjetives.one === value) {
      setFormAdjectives("one", "");
    } else if (form.applicant_adjetives.two === value) {
      setFormAdjectives("two", "");
    } else if (form.applicant_adjetives.three === value) {
      setFormAdjectives("three", "");
    } else if (form.applicant_adjetives.one === "") {
      setFormAdjectives("one", value);
    } else if (form.applicant_adjetives.two === "") {
      setFormAdjectives("two", value);
    } else if (form.applicant_adjetives.three === "") {
      setFormAdjectives("three", value);
    }
  };

  const handleChangeAddServices = (value) => {
    let newArr = form.applicant_additional_services;

    let exists = newArr.findIndex((date) => date.value === value);

    if (exists === -1) {
      if (newArr.length < 5) {
        newArr.push({ value });
        setForm({
          ...form,
          applicant_additional_services: newArr,
        });
      }
    } else {
      newArr.splice(exists, 1);
      setForm({
        ...form,
        applicant_additional_services: newArr,
      });
    }
  };

  const handleResponse = (res) => {
    return setIsResultTabVisible(res);
  };

  const handleLoadingFinish = (loading) => {
    return setIsLoadingTabVisible(loading);
  };

  const verifyAge = (e) => {
    let _birthdate = e.target.value;
    let today = new Date();
    let birthdate = new Date(_birthdate);
    let age = today.getFullYear() - birthdate.getFullYear();
    let m = birthdate.getMonth() - today.getMonth();
    let day =
      Number(_birthdate.slice(_birthdate.length - 2, _birthdate.length)) -
      Number(`${today}`.slice(7, 10));

    let greater_than =
      age === 18 && m < 0
        ? false
        : age === 18 && m === 0 && day < 0
        ? false
        : age < 17
        ? false
        : true;

    setValidAge(greater_than);

    return !greater_than;
  };

  return (
    <>
      {/* Class names starting with "ffa" refer to the file: FormForApplicant.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-form-applicant")}
        </title>
      </Helmet>

      <HeaderInAdmin typeAdmin="applicant" />

      <div className="ffa_main_container">
        {/* ------------------ Tab Loading ------------------ */}
        <div
          className={`ffa_tab_loading_container ${
            isLoadingTabVisible && "ffa_tab_loading_active"
          }`}
        >
          {isLoadingTabVisible && (
            <div className="ffa_loading_illustration_container">
              <img
                src={assets.icons.iconSending}
                alt="Sending"
                className="ffa_loading_img"
              />
              <span className="ffa_loading_active_loader" />
            </div>
          )}
        </div>
        {/* ------------------ /Tab Loading ------------------ */}

        {/* ------------------ Tab Response ------------------ */}
        <div
          className={`ffa_tab_responseMsm_container ${
            isResultTabVisible !== false && "ffa_tab_responseMsm_active"
          }`}
        >
          <h4>
            {isResultTabVisible === "successful"
              ? t("form-register-successful")
              : t("form-register-failed")}
          </h4>

          <div className="ffa_tab_responseMsm_img_cont">
            {isResultTabVisible === "successful" ? (
              <img
                src={assets.imgs.successful}
                alt="Register successful"
                className="ffa_tab_responseMsm_img"
              />
            ) : (
              <img
                src={assets.imgs.failed}
                alt="Register failed"
                className="ffa_tab_responseMsm_img"
              />
            )}
          </div>

          {isResultTabVisible === "successful" ? (
            <p className="ffa_tab_responseMsm_oops">
              {t("Erfolgreiche Datenaktualisierung")}
            </p>
          ) : (
            <p className="ffa_tab_responseMsm_oops">
              {t("form-register-failed-oops")}
            </p>
          )}

          {isResultTabVisible === "successful" ? (
            <Button
              type="link"
              link="/home-applicant"
              title={t("form-btn-register-close")}
            />
          ) : (
            <Button
              title={t("form-btn-register-retry")}
              onclick={() => handleResponse(false)}
            />
          )}
        </div>
        {/* ------------------ /Tab Response ------------------ */}

        <TabBarSteps
          currentFormStep={currentFormStep}
          arr={arrCompleted}
          onclick={(step) => handleChangeStep(step)}
        />

        <form id="register-applicant" className="ffa_form_container">
          {currentFormStep === 1 && (
            <Step
              numStep={1}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-applicant-step-1-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- ProfileFile -------------------- */}
              <div className="ffa_form_changeProfile">
                {form.applicant_profileFile !== undefined &&
                form.applicant_profileFile !== null ? (
                  <div className="ffa_profileFile_container">
                    <button
                      className="ffa_profileFile_btn"
                      onClick={() =>
                        setForm({
                          ...form,
                          applicant_profileFile: null,
                        })
                      }
                    >
                      {typeof form.applicant_profileFile === "object" ? (
                        <img
                          className="ffa_profileFile_img"
                          src={URL.createObjectURL(form.applicant_profileFile)}
                          alt="Profile File"
                        />
                      ) : (
                        <img
                          className="ffa_profileFile_img"
                          src={`https://companyu.de/backend/${perfil}`}
                          alt="Profile File"
                        />
                      )}

                      <p>{t("form-choose-other-image")}</p>
                    </button>
                  </div>
                ) : (
                  <Input
                    type="file"
                    id="applicant_profileFile"
                    nameInput="applicant_profileFile"
                    responseInput={(target, isOK) =>
                      setForm({
                        ...form,
                        applicant_profileFile: target.files[0],
                      })
                    }
                    label
                    childrenLabel={
                      <ChildrenLabelDefault
                        txt={t("form-applicant-step-1-profile-file")}
                      />
                    }
                  />
                )}

                <div className="ffa_form_change_container">
                  <Button
                    title="Stornieren"
                    otherClassNameBtn="ffa_form_change_imgProfile_cancel"
                    onclick={() =>
                      setForm({
                        ...form,
                        applicant_profileFile: perfil,
                      })
                    }
                  />

                  <Button
                    title="Halten"
                    otherClassNameBtn={
                      form.applicant_profileFile === null ||
                      form.applicant_profileFile === undefined ||
                      form.applicant_profileFile === perfil
                        ? "ffa_form_change_imgProfile_save_disabled"
                        : false
                    }
                    onclick={(e) => {
                      e.preventDefault();

                      if (
                        form.applicant_profileFile !== null &&
                        form.applicant_profileFile !== undefined &&
                        form.applicant_profileFile !== perfil
                      ) {
                        servicesUpdateImageProfile(
                          form.applicant_profileFile,
                          id,
                          token,
                          (res) => {
                            if (res.response) {
                              setResponseChangeProfile(true);

                              const setTimeoutResponseChangeProfile =
                                setTimeout(() => {
                                  setResponseChangeProfile(false);
                                  clearTimeout(setTimeoutResponseChangeProfile);
                                }, 10000);
                            }
                          },
                          (load) => console.log(load)
                        );
                      }
                    }}
                  />

                  {responseChangeProfile && <p>Erfolgreich gespeichert</p>}
                </div>
              </div>
              {/* -------------------- /ProfileFile ------------------------- */}

              {/* -------------------- Name -------------------- */}
              <Input
                id="applicant_name"
                nameInput="applicant_name"
                // placeholder={t("form-applicant-step-1-name")}
                placeholder="Jon"
                defaultValue={form.applicant_name}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-name")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Name ------------------------- */}

              {/* -------------------- Last Name -------------------- */}
              <Input
                id="applicant_lastname"
                nameInput="applicant_lastname"
                // placeholder={t("form-applicant-step-1-lastname")}
                placeholder="Gu"
                defaultValue={form.applicant_lastname}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-lastname")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Last Name -------------------- */}

              {/* -------------------- Residence city -------------------- */}
              <Input
                id="applicant_residence_city"
                nameInput="applicant_residence_city"
                // placeholder={t("form-applicant-step-1-residence-city")}
                placeholder="Bremen"
                defaultValue={form.applicant_residence_city}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-residence-city")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Residence city -------------------- */}

              {/* -------------------- Postal code -------------------- */}
              <Input
                id="applicant_postal_code"
                nameInput="applicant_postal_code"
                type="number"
                // placeholder={t("form-applicant-step-1-postal-code")}
                placeholder="000000"
                defaultValue={form.applicant_postal_code}
                minLength={11}
                maxLength={13}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-postal-code")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Postal code -------------------- */}

              {/* -------------------- Phone number -------------------- */}
              <Input
                id="applicant_phone_number"
                nameInput="applicant_phone_number"
                // placeholder={t("form-applicant-step-1-phone-number")}
                placeholder="04900000000"
                defaultValue={form.applicant_phone_number}
                type="tel"
                required
                handleBlurContainsError={(e) => {
                  let validNumberPhone = !regIsPhone.test(e.target.value);

                  return validNumberPhone;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-phone-number")}
                  />
                }
                errorMessage={
                  form.applicant_phone_number?.length === 0
                    ? t("form-required")
                    : !regIsPhone.test(form.applicant_phone_number)
                    ? t("form-applicant-step-1-please-valid-number")
                    : ""
                }
              />
              {/* -------------------- /Phone number -------------------- */}

              {/* -------------------- Birthdate -------------------- */}
              <Input
                id="applicant_birthdate"
                nameInput="applicant_birthdate"
                placeholder={t("form-applicant-step-1-birthdate")}
                defaultValue={form.applicant_birthdate}
                type="date"
                required
                handleBlurContainsError={(e) => {
                  return verifyAge(e);
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-birthdate")}
                  />
                }
                errorMessage={
                  !validAge
                    ? t("form-applicant-step-1-please-valid-age")
                    : t("form-required")
                }
              />
              {/* -------------------- /Birthdate -------------------- */}

              {/* -------------------- Gender -------------------- */}
              <Options
                label={t("form-applicant-step-1-gender")}
                arr={genderOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_gender",
                      value: value === form.applicant_gender ? "" : value,
                    },
                    value !== form.applicant_gender
                  );
                }}
                select={form.applicant_gender}
                required
                error={errorFree.applicant_gender === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Gender -------------------- */}

              {/* -------------------- Email -------------------- */}
              {/* <Input
                id="applicant_mail"
                nameInput="applicant_mail"
                // placeholder={t("form-applicant-step-1-mail")}
                placeholder="yourmail@mail.com"
                defaultValue={form.applicant_mail}
                type="email"
                required
                handleBlurContainsError={(e) => {
                  return (
                    e.target.value.length < 6 ||
                    !regIsMail.test(form.applicant_mail)
                  );
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-mail")}
                  />
                }
                errorMessage={
                  form.applicant_mail.length === 0
                    ? t("form-required")
                    : !regIsMail.test(form.applicant_mail)
                    ? t("form-applicant-step-1-please-valid-mail")
                    : ""
                }
              /> */}
              {/* -------------------- /Email -------------------- */}

              {/* -------------------- Password -------------------- */}
              {/* <Input
                id="applicant_password"
                nameInput="applicant_password"
                // placeholder={t("form-applicant-step-1-password")}
                placeholder="******"
                defaultValue={form.applicant_password}
                type="password"
                required
                handleBlurContainsError={(e) => {
                  return e.target.value.length < 6;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-password")}
                  />
                }
                errorMessage={
                  form.applicant_password.length === 0
                    ? t("form-required")
                    : form.applicant_password.length < 6
                    ? t("form-applicant-step-1-please-valid-password")
                    : ""
                }
              /> */}
              {/* -------------------- /Password -------------------- */}

              {/* -------------------- Repeat password -------------------- */}
              {/* <Input
                id="applicant_repeat_password"
                nameInput="applicant_repeat_password"
                // placeholder={t("form-applicant-step-1-repeat-password")}
                placeholder="******"
                defaultValue={form.applicant_repeat_password}
                type="password"
                required
                handleBlurContainsError={(e) => {
                  return e.target.value !== form.applicant_password;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-repeat-password")}
                  />
                }
                errorMessage={
                  form.applicant_repeat_password.length === 0
                    ? t("form-required")
                    : form.applicant_repeat_password !== form.applicant_password
                    ? t("form-applicant-step-1-please-valid-repeat-password")
                    : ""
                }
              /> */}
              {/* -------------------- /Repeat password -------------------- */}

              {/* ----------------- Terms and conditions ----------------- */}
              {/* <div className="ffa_terms_container">
                <div className="ffa_terms_checkbox_container">
                  <input
                    id="applicant_terms"
                    name="applicant_terms"
                    type="checkbox"
                    onChange={(e) => handleChangeCheckbox(e.target)}
                    defaultChecked={form.applicant_terms}
                  />
                </div>

                <div>
                  <label htmlFor="applicant_terms">
                    {t("form-accept-terms-and-conditions")}{" "}
                  </label>
                  <Link to="/data-protection" target="_blank">
                    {t("form-see-terms-and-conditions")}
                  </Link>
                </div>
              </div>

              <div>
                {!form.applicant_terms && (
                  <p style={{ fontSize: "1.3rem", color: "#f00" }}>
                    {t("form-required")}
                  </p>
                )}
              </div> */}
              {/* ----------------- /Terms and conditions ----------------- */}
            </Step>
          )}

          {currentFormStep === 2 && (
            <Step
              numStep={2}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-applicant-step-2-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- What job -------------------- */}
              <Selector
                id="applicant_what_job"
                label={t("form-applicant-step-2-what-job")}
                arr={experienceOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_what_job",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_what_job}
                required
                error={errorFree.applicant_what_job === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /What job -------------------- */}

              {/* -------------------- Apply -------------------- */}
              <Options
                label={t("form-applicant-step-2-apply")}
                arr={[
                  {
                    name: t("form-applicant-step-2-lateral"),
                    value: "lateral",
                  },
                  {
                    name: t("form-applicant-step-2-journeyman"),
                    value: "journeyman",
                  },
                  {
                    name: t("form-applicant-step-2-master"),
                    value: "master",
                  },
                ]}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_apply",
                      value: value === form.applicant_apply ? "" : value,
                    },
                    value !== form.applicant_apply
                  );
                }}
                select={form.applicant_apply}
                required
                error={errorFree.applicant_apply === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Apply -------------------- */}

              {/* -------------------- Adjetives -------------------- */}
              <Options
                otherClassNameOption="ffa_options_adjetives"
                label={t("form-applicant-step-2-adjective")}
                arr={adjetivesOptions}
                onclick={(value) => handleChangeAdjetives(value)}
                select={form.applicant_adjetives}
                arrSelects={[
                  { value: form.applicant_adjetives.one },
                  { value: form.applicant_adjetives.two },
                  { value: form.applicant_adjetives.three },
                ]}
              />
              {/* -------------------- /Adjetives -------------------- */}
            </Step>
          )}

          {currentFormStep === 3 && (
            <Step
              numStep={3}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-applicant-step-3-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- Exp -------------------- */}
              {/* <Selector
                label={t("form-applicant-step-3-time-exp")}
                arr={timeExpOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_time_exp",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_time_exp}
                required
                error={errorFree.applicant_time_exp === false}
                errorMessage={t("form-required")}
              /> */}
              {/* -------------------- /Exp -------------------- */}

              {/* -------------------- Travel -------------------- */}
              <Selector
                label={t("form-applicant-step-3-travel")}
                arr={travelOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_travel",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_travel}
                required
                error={errorFree.applicant_travel === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Travel -------------------- */}

              {/* -------------------- Qualifications -------------------- */}
              <TextArea
                id="applicant_selfdescription"
                label={t("form-applicant-step-3-selfdescription")}
                placeholder={t("form-applicant-step-3-selfdescription")}
                responseTextArea={(target, isOK) => {
                  handleChangeInput(target, isOK);
                }}
                defaultValue={form.applicant_selfdescription}
                required
                error={errorFree.applicant_selfdescription === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Qualifications -------------------- */}

              {/* -------------------- Available -------------------- */}
              <Options
                label={t("form-applicant-step-3-availability")}
                arr={availableOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_availability",
                      value: value === form.applicant_availability ? "" : value,
                    },
                    value !== form.applicant_availability
                  );
                }}
                select={form.applicant_availability}
                required
                error={errorFree.applicant_availability === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Available -------------------- */}

              {/* ----------------- Language preference ----------------- */}
              <Selector
                label={t("form-applicant-step-3-lng-pref")}
                arr={lngPrefOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_lng_pref",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_lng_pref}
                required
                error={errorFree.applicant_lng_pref === false}
                errorMessage={t("form-required")}
              />
              {/* ----------------- /Language preference ----------------- */}

              {/* -------------------- License -------------------- */}
              <Options
                label={t("form-applicant-step-3-license")}
                arr={licenseOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_driving_license",
                      value:
                        value === form.applicant_driving_license ? "" : value,
                    },
                    value !== form.applicant_driving_license
                  );
                }}
                select={form.applicant_driving_license}
                required
                error={errorFree.applicant_driving_license === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /License -------------------- */}

              {/* ---------------- Salary expectation  ---------------- */}
              <Input
                type="range"
                id="applicant_salary"
                nameInput="applicant_salary"
                min={0}
                max={150000}
                defaultValue={form.applicant_salary}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    txt={`${t(
                      "form-applicant-step-3-salary"
                    )}: ${thousandsSeparator(form.applicant_salary)} €`}
                  />
                }
              />
              {/* ---------------- /Salary expectation  ---------------- */}

              {/* ---------------- Additional Servicies ---------------- */}
              <Options
                otherClassNameOption="ffa_options_adjetives"
                label={t("form-applicant-step-3-additional-title")}
                arr={additionalServices}
                onclick={(value) => handleChangeAddServices(value)}
                select={form.applicant_additional_services}
                arrSelects={form.applicant_additional_services}
              />
              {/* ---------------- /Additional Servicies ---------------- */}
            </Step>
          )}

          {currentFormStep === 4 && (
            <Step
              numStep={4}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-applicant-step-4-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- Teamwork -------------------- */}
              <Selector
                id="applicant_teamwork"
                label={t("form-applicant-step-4-teamwork")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_teamwork",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_teamwork}
                required
                error={errorFree.applicant_teamwork === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Teamwork -------------------- */}

              {/* ------------------ Stress and pressure ------------------ */}
              <Selector
                id="applicant_stress_and_pressure"
                label={t("form-applicant-step-4-stress-and-pressure")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_stress_and_pressure",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_stress_and_pressure}
                required
                error={errorFree.applicant_stress_and_pressure === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Stress and pressure ------------------ */}

              {/* ------------------ I enjoy working ------------------ */}
              <Selector
                id="applicant_I_enjoy_working"
                label={t("form-applicant-step-4-I-enjoy-working")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_I_enjoy_working",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_I_enjoy_working}
                required
                error={errorFree.applicant_I_enjoy_working === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /I enjoy working ------------------ */}

              {/* ------------------ I improve my skills ------------------ */}
              <Selector
                id="applicant_I_improve_my_skills"
                label={t("form-applicant-step-4-I-improve-my-skills")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_I_improve_my_skills",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_I_improve_my_skills}
                required
                error={errorFree.applicant_I_improve_my_skills === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /I improve my skills ------------------ */}

              {/* ------------------ Adaptability ------------------ */}
              <Selector
                id="applicant_adaptability"
                label={t("form-applicant-step-4-adaptability")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_adaptability",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_adaptability}
                required
                error={errorFree.applicant_adaptability === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Adaptability ------------------ */}
            </Step>
          )}

          {currentFormStep === 5 && (
            <Step
              numStep={5}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-applicant-step-5-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
              lastStep
              onFinish={() =>
                servicesFormApplicant(
                  form,
                  id,
                  (res) => handleResponse(res),
                  (loading) => handleLoadingFinish(loading)
                )
              }
              custonNameBtnNext={t("form-btn-register")}
            >
              {/* ----------------- Conflicts Objectively ----------------- */}
              <Selector
                id="applicant_conflicts_objectively"
                label={t("form-applicant-step-5-conflicts-objectively")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_conflicts_objectively",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_conflicts_objectively}
                required
                error={errorFree.applicant_conflicts_objectively === false}
                errorMessage={t("form-required")}
              />
              {/* ----------------- /Conflicts Objectively ----------------- */}

              {/* ------------------ I enjoy learning ------------------ */}
              <Selector
                id="applicant_I_enjoy_learning"
                label={t("form-applicant-step-5-I-enjoy-learning")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_I_enjoy_learning",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_I_enjoy_learning}
                required
                error={errorFree.applicant_I_enjoy_learning === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /I enjoy learning ------------------ */}

              {/* ------------------ Responsibility ------------------ */}
              <Selector
                id="applicant_responsibility"
                label={t("form-applicant-step-5-responsibility")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_responsibility",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_responsibility}
                required
                error={errorFree.applicant_responsibility === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Responsibility ------------------ */}

              {/* ------------------ Extra hours ------------------ */}
              <Selector
                id="applicant_extra_hours"
                label={t("form-applicant-step-5-extra-hours")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_extra_hours",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_extra_hours}
                required
                error={errorFree.applicant_extra_hours === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Extra hours ------------------ */}

              {/* ------------------ Satisfied in job ------------------ */}
              <Selector
                id="applicant_satisfied_in_job"
                label={t("form-applicant-step-5-satisfied-in-job")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_satisfied_in_job",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_satisfied_in_job}
                required
                error={errorFree.applicant_satisfied_in_job === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Satisfied in job ------------------ */}

              {/* -------------------- Short comment -------------------- */}
              <TextArea
                id="applicant_short_comment"
                label={t("form-applicant-step-5-short-comment")}
                placeholder={t("form-applicant-step-5-short-comment")}
                responseTextArea={(target, isOK) => {
                  handleChangeInput(target, isOK);
                }}
                defaultValue={form.applicant_short_comment}
                error={errorFree.applicant_short_comment === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Short comment -------------------- */}
            </Step>
          )}
        </form>
      </div>
    </>
  );
};

export default FormForApplicant;
